import { GatsbyBrowser } from 'gatsby'

import { wrapWithEnv } from './src/global/wrapWithEnv'
import { redirectToTheRightDomain } from './src/utils/redirects'

if (process.env.NODE_ENV !== 'development') redirectToTheRightDomain()

if (
  process.env.NODE_ENV === 'development' &&
  process.env.GATSBY_MOCK_SERVER === 'true'
) {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser')

  worker.start()
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = wrapWithEnv
