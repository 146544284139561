/* eslint-disable react/prop-types */
import { GatsbyBrowser, withPrefix } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

export const wrapWithEnv: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <>
    <Helmet>
      <script type="text/javascript" src={withPrefix(`/env.js`)} />
    </Helmet>
    {element}
  </>
)
