import { backofficeOnlyConfig } from '../config/global'

export const redirectToTheRightDomain = () => {
  if (
    typeof window !== 'undefined' &&
    window.location.href.includes('usehurrier.com/dashboard/dodo')
  ) {
    const newHostname = new URL(backofficeOnlyConfig.dodoDashboardBaseURL)
      .hostname

    window.location.hostname = newHostname
  }
}
